<template>
  <v-container class="task-modify" fluid>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <entity-modify-header class="mb-10" :title="title" />

      <template v-if="!isLoading && initialData">
        <form-builder
          v-if="useTemplate"
          :schema="schema"
          :initial-data="initialData"
          :is-tabs="useTemplate"
          is-tasks
          :tabs="tabs"
          :active-tab="tab"
          @submit="submit"
          @change-tab="changeTab"
          @save-data="saveData"
        >
          <template #footer="{ valid }">
            <v-row class="footer-row mt-4" :class="{ valid: checkValid(valid) }">
              <v-col>
                <v-btn v-if="tab >= 1" color="secondary" block class="primary--text" @click="changeTab(tab - 1)">
                  {{ $t('button.back') }}
                </v-btn>
                <v-btn v-else color="secondary" block class="primary--text" :to="backRoute">{{
                  $t('button.cancel')
                }}</v-btn>
              </v-col>
              <v-col>
                <v-btn
                  v-if="tab < tabs.length - 1"
                  type="button"
                  color="primary"
                  :disabled="tabs[1].disabled"
                  block
                  @click.prevent="changeTab"
                >
                  {{ $t('button.continue') }}
                </v-btn>
                <v-btn v-else :disabled="!checkValid(valid)" type="submit" color="primary" block>{{
                  submitButtonText
                }}</v-btn>
              </v-col>
            </v-row>
          </template>
        </form-builder>
        <form-builder
          v-else
          :schema="isRequestTask ? schemaRequestForm : schema"
          :initial-data="initialData"
          @submit="submit"
        >
          <template #footer="{ valid }">
            <v-row class="mt-10">
              <v-col>
                <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
              </v-col>
              <v-col>
                <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                  {{ submitButtonText }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </form-builder>
      </template>
    </template>
  </v-container>
</template>

<script>
import { format } from 'date-fns';

// Models
import { rules } from '@/schemas/validationRules';
import { schema, schemaWithTemplate } from '@/schemas/task.schema';
import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from '@/schemas/schemaItemTypes';

// Utils
import { isEqual } from '@/utils/common';
import { clone } from '@/utils/clone';
import { createModelData } from '@/schemas/createModelData';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import BaseLoader from '@/components/BaseLoader.vue';

// Constants
import { TASKS, TASKS_DETAILED } from '@/constants/routes';
import { CREATE_TASK, EDIT_TASK } from '@/constants/analyticsActions';
import { TAB_BASE_INFO, TAB_REQUEST_FORM } from '@/constants/tabs';

// Services
import issuesService from '@/services/issues';
import mediaService from '@/services/media';
import requestsService from '@/services/requests';

import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';

export default {
  name: 'TaskModify',

  components: { FormBuilder, EntityModifyHeader, BaseLoader },
  props: {
    isEdit: { type: Boolean, default: false },
    useTemplate: { type: Boolean, default: false },
  },

  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      initialData: null,
      isRequestTask: false,
      isUpload: false,
      requestForm: null,
      tab: 0,
      tabs: [
        {
          label: 'tab.base_info',
          tab: TAB_BASE_INFO,
          valid: false,
          disabled: false,
          data: createModelData(schema),
        },
        {
          label: 'tab.request_form',
          tab: TAB_REQUEST_FORM,
          valid: false,
          disabled: true,
          data: null,
        },
      ],
      state: null,
      requestFields: [],
    };
  },

  computed: {
    title() {
      return this.isEdit ? this.$t('task.edit_task') : this.$t('task.add_task');
    },
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_task') : this.$t('button.add_task');
    },
    backRoute() {
      if (this.isEdit) {
        return {
          name: TASKS_DETAILED,
          params: {
            id: this.id,
          },
        };
      }
      return this.$route.params.prevPage || { name: TASKS };
    },
    schemaList() {
      return [this.$options.schemaWithTemplate, this.schemaRequestForm];
    },
    schema() {
      if (this.useTemplate) {
        return this.schemaList[this.tab];
      }

      return this.$options.schema;
    },
    schemaRequestForm() {
      return this.requestFields.map(field => {
        let formItem = {};

        switch (field.fieldType) {
          case 'text':
            formItem = {
              type: schemaItemTypes.STRING,
              label: field.name,
              prop: field.id,
              notTranslate: true,
              rules: [rules.REQUIRED],
            };
            break;
          case 'date_time':
            formItem = {
              type: schemaItemTypes.ROW,
              children: [
                {
                  type: schemaItemTypes.DATE,
                  prop: 'date',
                  label: 'label.preferred_date',
                  rules: [rules.REQUIRED],
                  onlyFutureDate: true,
                },
                {
                  type: schemaItemTypes.TIME,
                  prop: 'time',
                  label: 'label.preferred_time',
                  rules: [rules.REQUIRED],
                  minuteInterval: 5,
                  payload: [
                    {
                      param: 'isToday',
                      from: ['date'],
                      serializer: serializers.IS_TODAY,
                    },
                  ],
                },
              ],
            };
            break;
          case 'attachments':
            formItem = {
              type: schemaItemTypes.FILE_LIST,
              label: field.name,
              prop: field.id,
              rules: [rules.REQUIRED, rules.ALL_LOADED],
              removeLabelSuffix: true,
              notTranslate: true,
              editable: true,
              accept: '*',
            };
            break;
          case 'rowadder':
            formItem = {
              type: schemaItemTypes.REQUEST_FORM_LIST,
              label: field.name,
              removeLabelSuffix: true,
              notTranslate: true,
              prop: field.id,
              uniqueKey: 'id',
              addButtonText: 'button.add_more',
              min: 1,
              children: field.fieldOptions.columns.map((column, index) => {
                return {
                  type: schemaItemTypes.STRING,
                  label: column.title,
                  prop: `column${index}`,
                  notTranslate: true,
                  rules: [rules.REQUIRED],
                };
              }),
            };
            break;
          default:
            break;
        }

        return formItem;
      });
    },
  },

  mounted() {
    if (this.isEdit) {
      this.initData();
    } else {
      this.initialData = createModelData(this.schema);
    }
  },

  methods: {
    async initData() {
      this.isLoading = true;
      try {
        const issue = await issuesService.getIssueById(this.$route.params.id);
        this.state = issue.state;
        this.isRequestTask = issue.isRequestIssue;

        this.initialData = this.isRequestTask
          ? await this.getInitialDataWithTemplate(issue)
          : await this.getInitialData(issue);
      } finally {
        this.isLoading = false;
      }
    },
    async getInitialData(issue) {
      return {
        ...issue,
        performers: {
          all: false,
          exclude: [],
          include: issue.performers.map(performer => performer),
        },
        client: issue.client.id ? issue.client : null,
        rolesPending: {
          all: false,
          include: [...(issue.roles.pending || [])],
          exclude: [],
        },
        rolesInProgress: {
          all: false,
          include: [...(issue.roles.inProgress || [])],
          exclude: [],
        },
        rolesCompleted: {
          all: false,
          include: [...(issue.roles.completed || [])],
          exclude: [],
        },
        paymentAmount: undefined,
        paymentContractor: undefined,
        media: await this.formatMedia(issue.media),
      };
    },
    async getInitialDataWithTemplate(issue) {
      this.requestFields = issue.requestFormData.fields;
      const initialData = {};

      for (let i = 0; i < this.requestFields.length; i += 1) {
        const field = this.requestFields[i];

        switch (field.fieldType) {
          case 'text':
            initialData[field.id] = field.value;
            break;
          case 'date_time':
            initialData.date = format(new Date(field.value), 'yyy-MM-dd');
            initialData.time = format(new Date(field.value), 'HH:mm');
            break;
          case 'attachments':
            // eslint-disable-next-line no-await-in-loop
            initialData[field.id] = await this.formatMedia(field.value.map((url, id) => ({ id, url })));
            break;
          case 'rowadder':
            initialData[field.id] = field.value.map(value => {
              const res = {};
              value.forEach((item, index) => {
                res[`column${index}`] = item;
              });
              return res;
            });
            break;
          default:
            break;
        }
      }

      return initialData;
    },
    saveData(data) {
      this.tabs[this.tab].data = { ...data };
      this.checkRequest();
    },
    async formatMedia(media) {
      const newMedia = await Promise.all(
        media.map(async file => {
          const newFile = { ...file };
          newFile.originalUrl = newFile.url;
          newFile.name = decodeURIComponent(newFile.url)
            .split('/')
            .pop();

          const { url, blob } = await this.getPrivateMediaObject(newFile.url);

          newFile.url = url;
          newFile.type = blob.type;
          newFile.size = blob.size;
          return newFile;
        })
      );

      return newMedia;
    },
    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      let bodyRequest = {};

      if (this.isEdit) {
        bodyRequest = this.isRequestTask ? this.createBodyRequestWithTemplate(data) : this.createBodyRequest(data);
      } else {
        bodyRequest = this.useTemplate ? this.createBodyRequestWithTemplate() : this.createBodyRequest(data);
      }

      this.isUpload = true;

      let saveTask = null;

      if (this.useTemplate || this.isRequestTask) {
        saveTask = this.isEdit ? issuesService.updateRequestIssueById : issuesService.createRequestIssue;
      } else {
        saveTask = this.isEdit ? issuesService.updateIssueById : issuesService.createIssue;
      }

      saveTask(bodyRequest, this.id)
        .then(task => {
          if (!task) return;

          analyticsService.track(this.isEdit ? EDIT_TASK : CREATE_TASK);
          notificationService.success(this.isEdit ? this.$t('task.edited') : this.$t('task.created'), 2000);
          this.$router.push({ name: TASKS_DETAILED, params: { id: task.id || this.id } });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isUpload = false;
        });
    },
    createBodyRequest(data) {
      const bodyRequest = {
        name: data.name,
        serviceType: data.serviceType.value,
        description: data.description,
        client: data.client?.id || null,
        unit: data.room?.id || data.unit.id,
        isInformClientViaPush: data.isInformClientViaPush,
        roles: {
          pending: [...data.rolesPending.include.map(role => role.id)],
          inProgress: [...data.rolesInProgress.include.map(role => role.id)],
          completed: [...data.rolesCompleted.include.map(role => role.id)],
        },
        deadline: data.deadline,
        isPaymentRequired: data.isPaymentRequired,
        paymentAmount: data.paymentAmount,
        paymentContractor: data.isPaymentRequired ? data.paymentContractor?.id : null,
        media: data.media,
      };

      return bodyRequest;
    },
    createBodyRequestWithTemplate(data) {
      let baseData;
      let requestFormData;

      if (!this.isEdit) {
        baseData = this.tabs[0].data;
        requestFormData = this.tabs[1].data;
      } else {
        requestFormData = data;
      }

      const fields = [];

      this.requestFields.forEach(field => {
        const fieldObject = {};

        if (this.isEdit) {
          fieldObject.issueField = field.id;
        } else {
          fieldObject.requestField = field.id;
        }

        switch (field.fieldType) {
          case 'text':
            fieldObject.value = requestFormData[field.id];
            break;
          case 'date_time':
            fieldObject.value = format(
              new Date(`${requestFormData.date}T${requestFormData.time}`),
              "yyyy-MM-dd'T'HH:mm:ssxxxxx"
            );
            break;
          case 'attachments':
            fieldObject.value = requestFormData[field.id].map(media => media.backgroundFileId || media.originalUrl);
            break;
          case 'rowadder':
            fieldObject.value = requestFormData[field.id].map(fieldValue => {
              return Object.values(fieldValue);
            });
            break;
          default:
            break;
        }

        if (fieldObject.requestField || fieldObject.issueField) {
          fields.push(fieldObject);
        }
      });

      const bodyRequest = {
        request: baseData?.request.id,
        unit: baseData?.room?.id || baseData?.unit.id,
        client: baseData?.client?.id || undefined,
        isInformClientViaPush: baseData?.isInformClientViaPush,
        form: {
          state: this.state,
          fields,
        },
      };

      return this.isEdit
        ? {
            form: {
              state: this.state,
              fields,
            },
          }
        : bodyRequest;
    },
    checkValid(valid) {
      this.tabs[this.tab].valid = valid;
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (!this.tabs[i].valid) {
          return false;
        }
      }
      return true;
    },
    changeTab(newTab) {
      if (typeof newTab === 'number') {
        this.initialData = this.tabs[newTab].data;
        this.tab = newTab;
        return;
      }
      this.initialData = this.tabs[this.tab + 1].data;
      this.tab += 1;
    },
    async checkRequest() {
      if (isEqual(this.tabs[0].data?.request, this.requestForm)) return;
      this.requestForm = clone(this.tabs[0].data?.request);

      this.tabs[1].disabled = true;

      if (!this.tabs[0].data?.request?.id) {
        this.state = null;
        this.requestFields = [];
        this.tabs[1].data = null;
        return;
      }

      requestsService.getRequestById(this.tabs[0].data?.request?.id).then(request => {
        if (!request.fields.length) return;

        this.state = request.state;
        this.requestFields = request.fields;

        this.tabs[1].disabled = false;

        this.tabs[1].data = createModelData(this.schemaRequestForm);
      });
    },
  },

  notificationItem: null,
  schema,
  schemaWithTemplate,
};
</script>

<style lang="scss">
.task-modify {
  max-width: 548px !important;
}
</style>
